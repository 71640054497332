.gp-input {
  letter-spacing: 0.5px;
  background-color: whitesmoke !important;
  border-radius: 5px;
}
/* 
gp-input:focus {
  border-color: orange;
}

.gp-input-group:focus-within {
  border-color: orange;
}

.gp-input-group:focus-within label {
  color: orange;
} */
